// src/components/Political.js

import React from 'react';
import { Box, Typography, List, ListItem, ListItemText } from '@mui/material';

/**
 * Political Component
 * @description Displays political organs, calendar, documents, etc.
 */

const sections = [
  'Political Organs',
  'Calendar',
  'Documents',
  'Election Information',
];

const sectionDetails = {
  'Political Organs': 'Information about the city council, mayor\'s office, and committees.',
  'Calendar': 'Schedule of council meetings, public hearings, and important dates.',
  'Documents': 'Access to public records, meeting minutes, and ordinances.',
  'Election Information': 'Details about upcoming elections, candidates, and voting procedures.',
};

function Political() {
  const [selectedSection, setSelectedSection] = React.useState('Political Organs');

  const detail = sectionDetails[selectedSection];

  return (
    <Box sx={{ display: 'flex', mt: 4, flexDirection: { xs: 'column', md: 'row' } }}>
      {/* Left Sidebar Menu */}
      <Box sx={{ width: { xs: '100%', md: '250px' }, mr: { md: 4 } }}>
        <nav aria-label="Political Navigation">
          <List component="nav">
            {sections.map((section) => (
              <ListItem
                button
                key={section}
                selected={selectedSection === section}
                onClick={() => setSelectedSection(section)}
                aria-current={selectedSection === section ? 'page' : undefined}
              >
                <ListItemText primary={section} />
              </ListItem>
            ))}
          </List>
        </nav>
      </Box>

      {/* Right Display Area */}
      <Box sx={{ flex: 1 }}>
        <Typography variant="h4" gutterBottom>
          {selectedSection}
        </Typography>
        <Typography variant="body1">
          {detail}
        </Typography>
        {/* Additional content can be added here, such as tables, lists, or links */}
      </Box>
    </Box>
  );
}

export default Political;
