// CommunityResources.js

import React from 'react';
import { Box, List, ListItem, ListItemText, Typography } from '@mui/material';

/**
 * CommunityResources Component
 * @description Displays community resources with a left menu and right display area.
 */

const resources = [
  'Education',
  'Health Services',
  'Volunteer Opportunities',
  'Community Groups',
  'Support Services',
];

const resourceDetails = {
  'Education': {
    description: 'Information on local schools and educational programs.',
    image: '/assets/education-resources.jpg',
  },
  // Add details for other resources
};

function CommunityResources() {
  const [selectedResource, setSelectedResource] = React.useState('Education');

  const resource = resourceDetails[selectedResource];

  return (
    <Box sx={{ display: 'flex', mt: 4, flexDirection: { xs: 'column', md: 'row' } }}>
      {/* Left Sidebar Menu */}
      <Box sx={{ width: { xs: '100%', md: '250px' }, mr: { md: 4 } }}>
        <nav aria-label="Community Resources Navigation">
          <List component="nav">
            {resources.map((resource) => (
              <ListItem
                button
                key={resource}
                selected={selectedResource === resource}
                onClick={() => setSelectedResource(resource)}
                aria-current={selectedResource === resource ? 'page' : undefined}
              >
                <ListItemText primary={resource} />
              </ListItem>
            ))}
          </List>
        </nav>
      </Box>

      {/* Right Display Area */}
      <Box sx={{ flex: 1 }}>
        <Typography variant="h4" gutterBottom>
          {selectedResource}
        </Typography>
        {resource && (
          <>
            <Typography variant="body1">
              {resource.description}
            </Typography>
            {/* Include image if available */}
          </>
        )}
      </Box>
    </Box>
  );
}

export default CommunityResources;
