// theme.js

import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    primary: {
      main: '#2c3e50', // Dark Blue
    },
    secondary: {
      main: '#27ae60', // Green
    },
    background: {
      default: '#f5f5f5',
    },
    text: {
      primary: '#333', // From index.css
    },
  },
  typography: {
    fontFamily: 'Arial, sans-serif',
    h1: {
      fontSize: '2.5rem',
      fontWeight: 700,
    },
    h2: {
      fontSize: '2rem',
      fontWeight: 600,
    },
    h3: {
      fontSize: '1.75rem',
      fontWeight: 600,
    },
    body1: {
      fontSize: '1rem',
    },
    // Add more typography variants as needed
  },
  components: {
    // Override default component styles
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: '8px',
          textTransform: 'none',
        },
        containedPrimary: {
          backgroundColor: '#2c3e50',
          color: '#fff',
          '&:hover': {
            backgroundColor: '#1a252f',
          },
        },
        containedSecondary: {
          backgroundColor: '#27ae60',
          color: '#fff',
          '&:hover': {
            backgroundColor: '#1e8e52',
          },
        },
      },
    },
    MuiLink: {
      styleOverrides: {
        root: {
          color: '#27ae60',
          '&:hover': {
            textDecoration: 'underline',
          },
        },
      },
    },
    // Additional component overrides can be added here
  },
});

export default theme;
