// src/components/Mobility.js

import React from 'react';
import { Box, Typography, List, ListItem, ListItemText } from '@mui/material';

/**
 * Mobility Component
 * @description Provides information on mobility options in the city.
 */

const mobilityOptions = [
  'Public Transportation',
  'Bike Sharing',
  'Electric Scooters',
  'Car Rentals',
  'Parking Information',
];

const optionDetails = {
  'Public Transportation': 'Information on bus and metro routes, schedules, and fares.',
  'Bike Sharing': 'Locations and details of bike-sharing stations.',
  'Electric Scooters': 'How to rent and use electric scooters in the city.',
  'Car Rentals': 'List of car rental services and locations.',
  'Parking Information': 'Details on parking zones, fees, and regulations.',
};

function Mobility() {
  const [selectedOption, setSelectedOption] = React.useState('Public Transportation');

  return (
    <Box sx={{ display: 'flex', mt: 4, flexDirection: { xs: 'column', md: 'row' } }}>
      {/* Left Sidebar Menu */}
      <Box sx={{ width: { xs: '100%', md: '250px' }, mr: { md: 4 } }}>
        <nav aria-label="Mobility Options Navigation">
          <List component="nav">
            {mobilityOptions.map((option) => (
              <ListItem
                button
                key={option}
                selected={selectedOption === option}
                onClick={() => setSelectedOption(option)}
                aria-current={selectedOption === option ? 'page' : undefined}
              >
                <ListItemText primary={option} />
              </ListItem>
            ))}
          </List>
        </nav>
      </Box>

      {/* Right Display Area */}
      <Box sx={{ flex: 1 }}>
        <Typography variant="h4" gutterBottom>
          {selectedOption}
        </Typography>
        <Typography variant="body1">
          {optionDetails[selectedOption]}
        </Typography>
        {/* Additional content can be added here */}
      </Box>
    </Box>
  );
}

export default Mobility;
