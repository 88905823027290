// LocalCommerce.js

import React from 'react';
import { Box, List, ListItem, ListItemText, Typography, Card, CardMedia, CardContent } from '@mui/material';

const businesses = [
  'Artisanal Bakery',
  'Toy Factory Outlet',
  'Slate Craft Workshop',
  'Local Brewery',
  'Handcrafted Goods',
];

const businessDetails = {
  'Artisanal Bakery': {
    description: 'Freshly baked goods made daily using traditional recipes.',
    image: '/assets/commerce/bakery.jpg',
  },
  'Toy Factory Outlet': {
    description: 'Wide selection of locally made toys for all ages.',
    image: '/assets/commerce/toy-factory.jpg',
  },
  'Slate Craft Workshop': {
    description: 'Unique slate crafts and souvenirs handmade by local artisans.',
    image: '/assets/commerce/slate-workshop.jpg',
  },
  // ...other businesses
};

function LocalCommerce() {
  const [selectedBusiness, setSelectedBusiness] = React.useState('Artisanal Bakery');

  const business = businessDetails[selectedBusiness];

  return (
    <Box sx={{ display: 'flex', mt: 4, flexDirection: { xs: 'column', md: 'row' } }} id="local-commerce">
      {/* Left Sidebar Menu */}
      <Box sx={{ width: { xs: '100%', md: '250px' }, mr: { md: 4 } }}>
        <nav aria-label="Local Commerce Navigation">
          <List component="nav">
            {businesses.map((business) => (
              <ListItem
                button
                key={business}
                selected={selectedBusiness === business}
                onClick={() => setSelectedBusiness(business)}
                aria-current={selectedBusiness === business ? 'page' : undefined}
              >
                <ListItemText primary={business} />
              </ListItem>
            ))}
          </List>
        </nav>
      </Box>

      {/* Right Display Area */}
      <Box sx={{ flex: 1 }}>
        <Typography variant="h4" gutterBottom>
          {selectedBusiness}
        </Typography>
        {business && (
          <>
            <Card sx={{ marginBottom: "20px" }}>
              <CardMedia
                component="img"
                height="300"
                image={business.image}
                alt={selectedBusiness}
              />
              <CardContent>
                <Typography variant="body1">
                  {business.description}
                </Typography>
              </CardContent>
            </Card>
          </>
        )}
      </Box>
    </Box>
  );
}

export default LocalCommerce;
