import React from "react";
import { Grid, Card, CardContent, Typography, Button } from "@mui/material";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";

const events = [
  {
    id: 1,
    name: "Art Festival",
    date: "December 12, 2024",
    location: "Downtown Square",
    description: "Experience local art and live performances.",
  },
  {
    id: 2,
    name: "Holiday Parade",
    date: "December 24, 2024",
    location: "Main Street",
    description: "Join the festive celebration with music and floats.",
  },
  {
    id: 3,
    name: "Charity Run",
    date: "January 10, 2025",
    location: "Community Park",
    description: "Support local charities by participating in this 5K run.",
  },
];

const Events = () => {
  return (
    <Grid container spacing={4} sx={{ padding: "20px" }}>
      {events.map((event) => (
        <Grid item xs={12} md={4} key={event.id}>
          <Card sx={{ padding: "15px" }}>
            <CardContent>
              <Typography variant="h5" component="div" gutterBottom>
                {event.name}
              </Typography>
              <Typography variant="body2" color="text.secondary">
                <CalendarMonthIcon sx={{ verticalAlign: "middle", marginRight: "8px" }} />
                {event.date} | {event.location}
              </Typography>
              <Typography variant="body2" sx={{ marginTop: "10px" }}>
                {event.description}
              </Typography>
              <Button
                variant="contained"
                color="primary"
                sx={{ marginTop: "10px" }}
                onClick={() => alert(`More info about ${event.name}`)}
              >
                Learn More
              </Button>
            </CardContent>
          </Card>
        </Grid>
      ))}
    </Grid>
  );
};

export default Events;
