// NewsUpdates.js

import React from "react";
import { Grid, Card, CardMedia, CardContent, Typography } from "@mui/material";

/**
 * NewsUpdates Component
 * @description Displays news updates in a card layout.
 */
const newsItems = [
  {
    id: 1,
    title: "City Council Meeting Highlights",
    image: "/assets/news-image.jpg",
    summary: "Summary of the latest decisions and discussions.",
  },
  {
    id: 1,
    title: "Trilobite Fossil Exhibition Opens",
    image: "/assets/news/trilobite-exhibition.jpg",
    summary: "A new exhibition showcasing rare trilobite fossils.",
  },
  {
    id: 2,
    title: "Hockey Team Wins Championship",
    image: "/assets/news/hockey-championship.jpg",
    summary: "Our local hockey team clinched the regional title.",
  },
];

const NewsUpdates = () => {
  return (
    <section>
      <Typography variant="h4" align="center" gutterBottom sx={{ mt: 4 }}>
        News & Updates
      </Typography>
      <Grid container spacing={4} sx={{ padding: "20px" }}>
        {newsItems.map((news) => (
          <Grid item xs={12} md={4} key={news.id}>
            <Card>
              <CardMedia component="img" height="140" image={news.image} alt={news.title} />
              <CardContent>
                <Typography gutterBottom variant="h6" component="div">
                  {news.title}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  {news.summary}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    </section>
  );
};

export default NewsUpdates;
