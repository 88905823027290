// Tourism.js

import React from 'react';
import { Box, List, ListItem, ListItemText, Typography, Card, CardMedia, CardContent } from '@mui/material';

const attractions = [
  'Trilobite Fossil Site',
  'Historic Slate Mines',
  'Roman Gold Mines',
  'Mountain Trails',
  'Hockey Arena',
  'Toy Museum',
  'Valley Viewpoint',
];

const attractionDetails = {
  'Trilobite Fossil Site': {
    description: 'Discover ancient trilobite fossils dating back millions of years.',
    image: '/assets/tourism/trilobite-site.jpg',
  },
  'Historic Slate Mines': {
    description: 'Explore the history of slate mining in our region.',
    image: '/assets/tourism/slate-mines.jpg',
  },
  'Roman Gold Mines': {
    description: 'Visit the remnants of Roman gold mining operations.',
    image: '/assets/tourism/roman-gold-mines.jpg',
  },
  'Mountain Trails': {
    description: 'Hike scenic trails with breathtaking views of valleys and mountains.',
    image: '/assets/tourism/mountain-trails.jpg',
  },
  'Hockey Arena': {
    description: 'Enjoy a game at our state-of-the-art hockey arena.',
    image: '/assets/tourism/hockey-arena.jpg',
  },
  'Toy Museum': {
    description: 'Relive childhood memories at our local toy museum.',
    image: '/assets/tourism/toy-museum.jpg',
  },
  'Valley Viewpoint': {
    description: 'Capture stunning panoramas of our beautiful valleys.',
    image: '/assets/tourism/valley-viewpoint.jpg',
  },
};

function Tourism() {
  const [selectedAttraction, setSelectedAttraction] = React.useState('Trilobite Fossil Site');

  const attraction = attractionDetails[selectedAttraction];

  return (
    <Box sx={{ display: 'flex', mt: 4, flexDirection: { xs: 'column', md: 'row' } }} id="tourism">
      {/* Left Sidebar Menu */}
      <Box sx={{ width: { xs: '100%', md: '250px' }, mr: { md: 4 } }}>
        <nav aria-label="Tourism Navigation">
          <List component="nav">
            {attractions.map((attraction) => (
              <ListItem
                button
                key={attraction}
                selected={selectedAttraction === attraction}
                onClick={() => setSelectedAttraction(attraction)}
                aria-current={selectedAttraction === attraction ? 'page' : undefined}
              >
                <ListItemText primary={attraction} />
              </ListItem>
            ))}
          </List>
        </nav>
      </Box>

      {/* Right Display Area */}
      <Box sx={{ flex: 1 }}>
        <Typography variant="h4" gutterBottom>
          {selectedAttraction}
        </Typography>
        {attraction && (
          <>
            <Card sx={{ marginBottom: "20px" }}>
              <CardMedia
                component="img"
                height="300"
                image={attraction.image}
                alt={selectedAttraction}
              />
              <CardContent>
                <Typography variant="body1">
                  {attraction.description}
                </Typography>
              </CardContent>
            </Card>
          </>
        )}
      </Box>
    </Box>
  );
}

export default Tourism;
