// src/components/Investment.js

import React from 'react';
import { Box, Typography, Card, CardContent } from '@mui/material';

/**
 * Investment Component
 * @description Provides information on investment opportunities in the city.
 */

function Investment() {
  return (
    <Box sx={{ mt: 4 }}>
      <Typography variant="h4" gutterBottom>
        Investment in the City
      </Typography>
      <Typography variant="body1" paragraph>
        Discover opportunities to invest in our city's growth and development.
      </Typography>
      <Card sx={{ mb: 2 }}>
        <CardContent>
          <Typography variant="h5">Business Development Zones</Typography>
          <Typography variant="body2">
            Learn about areas designated for commercial development with tax incentives.
          </Typography>
        </CardContent>
      </Card>
      <Card sx={{ mb: 2 }}>
        <CardContent>
          <Typography variant="h5">Public-Private Partnerships</Typography>
          <Typography variant="body2">
            Explore partnerships with the city on infrastructure projects.
          </Typography>
        </CardContent>
      </Card>
      {/* Additional investment opportunities can be added here */}
    </Box>
  );
}

export default Investment;
