// History.js

import React from 'react';
import { Box, Typography, Card, CardMedia, CardContent } from '@mui/material';

function History() {
  return (
    <Box sx={{ mt: 4 }} id="history">
      <Typography variant="h4" gutterBottom>
        Our Rich History
      </Typography>

      <Card sx={{ mb: 4 }}>
        <CardContent>
          <Typography variant="h5">Mining Heritage</Typography>
          <Typography variant="body1" paragraph>
            Our city has a deep-rooted history in mining, from ancient Roman gold mines to modern slate extraction.
          </Typography>
        </CardContent>
        <CardMedia
          component="img"
          height="200"
          image="/assets/history/mining.jpg"
          alt="Mining Heritage"
        />
      </Card>

      <Card sx={{ mb: 4 }}>
        <CardContent>
          <Typography variant="h5">Roman Exploration</Typography>
          <Typography variant="body1" paragraph>
            The Romans left their mark through exploration and mining activities, traces of which can still be found today.
          </Typography>
        </CardContent>
        <CardMedia
          component="img"
          height="200"
          image="/assets/history/roman-exploration.jpg"
          alt="Roman Exploration"
        />
      </Card>

      <Card sx={{ mb: 4 }}>
        <CardContent>
          <Typography variant="h5">Trilobite Fossils</Typography>
          <Typography variant="body1" paragraph>
            Our region is renowned for trilobite fossils, attracting researchers and enthusiasts from around the world.
          </Typography>
        </CardContent>
        <CardMedia
          component="img"
          height="200"
          image="/assets/history/trilobites.jpg"
          alt="Trilobite Fossils"
        />
      </Card>
    </Box>
  );
}

export default History;
