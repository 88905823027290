// src/components/ResidentSpotlight.js

import React from "react";
import { Grid, Card, CardMedia, CardContent, Typography } from "@mui/material";

/**
 * ResidentSpotlight Component
 * @description Highlights outstanding residents in a card layout.
 */
const residents = [
  {
    id: 1,
    name: "Sarah Johnson",
    image: "/assets/resident-sarah.jpg",
    story: "Sarah has been volunteering in the community for over 10 years.",
  },
  {
    id: 2,
    name: "James Lee",
    image: "/assets/resident-james.jpg",
    story: "James organized the recent charity fundraiser that raised $50K.",
  },
];

const ResidentSpotlight = () => {
  return (
    <section>
      <Typography variant="h4" align="center" gutterBottom sx={{ mt: 4 }}>
        Resident Spotlight
      </Typography>
      <Grid container spacing={4} sx={{ padding: "20px" }}>
        {residents.map((resident) => (
          <Grid item xs={12} md={6} key={resident.id}>
            <Card>
              <CardMedia
                component="img"
                height="200"
                image={resident.image}
                alt={`Photo of ${resident.name}`}
              />
              <CardContent>
                <Typography variant="h5">{resident.name}</Typography>
                <Typography variant="body2" color="text.secondary">
                  {resident.story}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    </section>
  );
};

export default ResidentSpotlight;
