// App.js

import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { CssBaseline, Container } from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";
import theme from "./theme";

import Header from "./components/Header";
import HeroSection from "./components/HeroSection";
import Services from "./components/Services";
import Tourism from "./components/Tourism";
import LocalCommerce from "./components/LocalCommerce";
import CommunityResources from "./components/CommunityResources";
import NewsUpdates from "./components/NewsUpdates";
import Events from "./components/Events";
import FeaturedInitiatives from "./components/FeaturedInitiatives";
import ResidentSpotlight from "./components/ResidentSpotlight";
import Footer from "./components/Footer";
import Political from "./components/Political";
import Investment from "./components/Investment";
import Mobility from "./components/Mobility";
import Trails from "./components/Trails";       // Import Trails component
import History from "./components/History";     // Import History component
import { Element } from 'react-scroll';         // Import Element from react-scroll

/**
 * App Component
 * @description The main component that sets up routing and integrates various components.
 */
function App() {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Router>
        <Header />
        <HeroSection />
        <main>
          <Container maxWidth="lg">
            {/* Wrap components with Element for smooth scrolling */}
            <Element name="news-updates" id="news-updates">
              <NewsUpdates />
            </Element>
            <Element name="services" id="services">
              <Services />
            </Element>
            <Element name="mobility" id="mobility">
              <Mobility />
            </Element>
            <Element name="tourism" id="tourism">
              <Tourism />
            </Element>
            <Element name="local-commerce" id="local-commerce">
              <LocalCommerce />
            </Element>
            <Element name="community-resources" id="community-resources">
              <CommunityResources />
            </Element>
            <Element name="political" id="political">
              <Political />
            </Element>
            <Element name="investment" id="investment">
              <Investment />
            </Element>
            <Element name="trails" id="trails">
              <Trails />
            </Element>
            <Element name="history" id="history">
              <History />
            </Element>
            <Element name="events" id="events">
              <Events />
            </Element>
            <Element name="featured-initiatives" id="featured-initiatives">
              <FeaturedInitiatives />
            </Element>
            <Element name="resident-spotlight" id="resident-spotlight">
              <ResidentSpotlight />
            </Element>
          </Container>
        </main>
        <Footer />
      </Router>
    </ThemeProvider>
  );
}

export default App;
