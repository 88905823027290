// src/components/FeaturedInitiatives.js

import React from 'react';
import { Grid, Card, CardMedia, CardContent, Typography } from '@mui/material';

/**
 * FeaturedInitiatives Component
 * @description Displays featured initiatives in a card layout.
 */

const initiatives = [
  {
    id: 1,
    title: 'Green City Project',
    image: '/assets/initiative-green-city.jpg',
    summary: 'Our commitment to sustainability and green energy.',
  },
  {
    id: 2,
    title: 'Community Art Programs',
    image: '/assets/initiative-art-programs.jpg',
    summary: 'Promoting local artists and cultural events.',
  },
  {
    id: 3,
    title: 'Public Transportation Expansion',
    image: '/assets/initiative-transport.jpg',
    summary: 'Improving connectivity across the city.',
  },
];

const FeaturedInitiatives = () => {
  return (
    <section>
      <Typography variant="h4" align="center" gutterBottom sx={{ mt: 4 }}>
        Featured Initiatives
      </Typography>
      <Grid container spacing={4} sx={{ padding: '20px' }}>
        {initiatives.map((initiative) => (
          <Grid item xs={12} md={4} key={initiative.id}>
            <Card>
              <CardMedia
                component="img"
                height="140"
                image={initiative.image}
                alt={initiative.title}
              />
              <CardContent>
                <Typography gutterBottom variant="h6" component="div">
                  {initiative.title}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  {initiative.summary}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    </section>
  );
};

export default FeaturedInitiatives;
