// Header.js

import React from "react";
import { AppBar, Toolbar, Typography, Container } from "@mui/material";
import { Link as ScrollLink } from "react-scroll";
import Logo from "../assets/logo.svg"; // Import the town logo

/**
 * Header Component
 * @description Contains the upper navigation bar and main navigation bar.
 */
function Header() {
  return (
    <header>
      {/* Upper Navigation Bar */}
      <AppBar position="static" color="primary">
        <Toolbar variant="dense">
          <Container
            maxWidth="lg"
            sx={{ display: "flex", justifyContent: "flex-end", alignItems: "center" }}
            component="nav"
            aria-label="Secondary navigation"
          >
            <ScrollLink
              to="services"
              smooth={true}
              duration={500}
              offset={-70}
              style={{ margin: '0 16px', cursor: 'pointer', color: 'inherit', textDecoration: 'none' }}
            >
              Services
            </ScrollLink>
            <ScrollLink
              to="mobility"
              smooth={true}
              duration={500}
              offset={-70}
              style={{ margin: '0 16px', cursor: 'pointer', color: 'inherit', textDecoration: 'none' }}
            >
              Mobility
            </ScrollLink>
            <ScrollLink
              to="tourism"
              smooth={true}
              duration={500}
              offset={-70}
              style={{ margin: '0 16px', cursor: 'pointer', color: 'inherit', textDecoration: 'none' }}
            >
              Tourism
            </ScrollLink>
            <ScrollLink
              to="local-commerce"
              smooth={true}
              duration={500}
              offset={-70}
              style={{ margin: '0 16px', cursor: 'pointer', color: 'inherit', textDecoration: 'none' }}
            >
              Local Commerce
            </ScrollLink>
            <ScrollLink
              to="community-resources"
              smooth={true}
              duration={500}
              offset={-70}
              style={{ margin: '0 16px', cursor: 'pointer', color: 'inherit', textDecoration: 'none' }}
            >
              Community Resources
            </ScrollLink>
            <ScrollLink
              to="political"
              smooth={true}
              duration={500}
              offset={-70}
              style={{ margin: '0 16px', cursor: 'pointer', color: 'inherit', textDecoration: 'none' }}
            >
              Political
            </ScrollLink>
            <ScrollLink
              to="investment"
              smooth={true}
              duration={500}
              offset={-70}
              style={{ margin: '0 16px', cursor: 'pointer', color: 'inherit', textDecoration: 'none' }}
            >
              Investment
            </ScrollLink>
          </Container>
        </Toolbar>
      </AppBar>

      {/* Main Navigation Bar */}
      <AppBar position="sticky" color="secondary">
        <Toolbar component="nav" aria-label="Main navigation">
          <Container
            maxWidth="lg"
            sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}
          >
            {/* Town Logo */}
            <ScrollLink
              to="hero"
              smooth={true}
              duration={500}
              offset={-70}
              style={{ display: 'flex', alignItems: 'center', cursor: 'pointer', color: 'inherit', textDecoration: 'none' }}
            >
              <img src={Logo} alt="Town Logo" style={{ height: '40px', marginRight: '10px' }} />
              <Typography variant="h6" color="inherit" sx={{ textDecoration: 'none' }}>
                City Hall
              </Typography>
            </ScrollLink>

            <nav>
              <ScrollLink
                to="news-updates"
                smooth={true}
                duration={500}
                offset={-70}
                style={{ margin: '0 16px', cursor: 'pointer', color: 'inherit', textDecoration: 'none' }}
              >
                News and Updates
              </ScrollLink>
              <ScrollLink
                to="events"
                smooth={true}
                duration={500}
                offset={-70}
                style={{ margin: '0 16px', cursor: 'pointer', color: 'inherit', textDecoration: 'none' }}
              >
                Upcoming and Past Events
              </ScrollLink>
              <ScrollLink
                to="featured-initiatives"
                smooth={true}
                duration={500}
                offset={-70}
                style={{ margin: '0 16px', cursor: 'pointer', color: 'inherit', textDecoration: 'none' }}
              >
                Featured Initiatives
              </ScrollLink>
              <ScrollLink
                to="resident-spotlight"
                smooth={true}
                duration={500}
                offset={-70}
                style={{ margin: '0 16px', cursor: 'pointer', color: 'inherit', textDecoration: 'none' }}
              >
                Resident Spotlight
              </ScrollLink>
            </nav>
          </Container>
        </Toolbar>
      </AppBar>
    </header>
  );
}

export default Header;
