// Trails.js

import React from 'react';
import { Box, Grid, Card, CardMedia, CardContent, Typography } from '@mui/material';

/**
 * Trails Component
 * @description Displays trails and nature sections.
 */

const trails = [
  {
    id: 1,
    name: 'Mountain Ridge Trail',
    image: '/assets/trails/mountain-ridge.jpg',
    description: 'Experience panoramic views along this challenging trail.',
  },
  {
    id: 2,
    name: 'Valley Loop',
    image: '/assets/trails/valley-loop.jpg',
    description: 'A gentle walk through the picturesque valley.',
  },
  // Ensure all 'id's are unique
];

function Trails() {
  return (
    <Box sx={{ mt: 4 }} id="trails">
      <Typography variant="h4" gutterBottom>
        Trails & Nature
      </Typography>
      <Grid container spacing={4}>
        {trails.map((trail) => (
          <Grid item xs={12} md={6} key={trail.id}>
            <Card>
              <CardMedia
                component="img"
                height="200"
                image={trail.image}
                alt={trail.name}
              />
              <CardContent>
                <Typography variant="h5">{trail.name}</Typography>
                <Typography variant="body2" color="text.secondary">
                  {trail.description}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
}

export default Trails;
