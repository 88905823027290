// src/components/Services.js

import React from 'react';
import { Box, List, ListItem, ListItemText, Typography } from '@mui/material';

/**
 * Services Component
 * @description Displays municipal services with a left menu and right display area.
 */

const services = [
  'Citizenship',
  'Initiatives',
  'Votes',
  'Water Services',
  'Finance',
  'Waste Collection',
  'Public Transportation',
  'Permit Applications',
  'Utilities',
  'Public Safety',
  'Recreation',
];

const serviceDetails = {
  'Citizenship': 'Information on obtaining citizenship documents, IDs, and registrations.',
  'Initiatives': 'Details on current city initiatives and how to participate.',
  'Votes': 'Information on voting procedures, locations, and schedules.',
  'Water Services': 'Manage your water services, billing, and report issues.',
  'Finance': 'Access to city budgets, financial reports, and tax information.',
  'Waste Collection': 'Find your waste collection schedule and guidelines.',
  'Public Transportation': 'Information on bus routes, schedules, and fares.',
  'Permit Applications': 'Apply for building permits and licenses.',
  'Utilities': 'Manage your water, electricity, and gas services.',
  'Public Safety': 'Access police, fire, and emergency services.',
  'Recreation': 'Discover parks, sports facilities, and community centers.',
};

function Services() {
  const [selectedService, setSelectedService] = React.useState('Citizenship');

  return (
    <Box sx={{ display: 'flex', mt: 4, flexDirection: { xs: 'column', md: 'row' } }} id="services">
      {/* Left Sidebar Menu */}
      <Box sx={{ width: { xs: '100%', md: '250px' }, mr: { md: 4 } }}>
        <nav aria-label="Services Navigation">
          <List component="nav">
            {services.map((service) => (
              <ListItem
                button
                key={service}
                selected={selectedService === service}
                onClick={() => setSelectedService(service)}
                aria-current={selectedService === service ? 'page' : undefined}
              >
                <ListItemText primary={service} />
              </ListItem>
            ))}
          </List>
        </nav>
      </Box>

      {/* Right Display Area */}
      <Box sx={{ flex: 1 }}>
        <Typography variant="h4" gutterBottom>
          {selectedService}
        </Typography>
        <Typography variant="body1">
          {serviceDetails[selectedService]}
        </Typography>
        {/* Additional content can be added here */}
      </Box>
    </Box>
  );
}

export default Services;
