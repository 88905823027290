// HeroSection.js

import React from "react";
import { Box, Typography, Button } from "@mui/material";
import { Link as ScrollLink } from "react-scroll";

/**
 * HeroSection Component
 * @description Displays the hero section with a background image and a call-to-action.
 */
function HeroSection() {
  return (
    <Box
      component="section"
      id="hero"
      className="hero-section"
      sx={{
        height: { xs: '60vh', md: '80vh' },
        backgroundImage: "url(/assets/hero-background.jpg)",
        backgroundSize: "cover",
        backgroundPosition: "center",
        position: "relative",
        color: "#fff",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        textAlign: "center",
      }}
    >
      <Box sx={{ position: "relative", zIndex: 1, maxWidth: '80%' }}>
        <Typography variant="h2" gutterBottom sx={{ fontWeight: 'bold' }}>
          Welcome to Valongo
        </Typography>
        <Typography variant="h5" gutterBottom>
          Your gateway to services, tourism, and community resources.
        </Typography>
        <ScrollLink
          to="services"
          smooth={true}
          duration={500}
          offset={-70}
        >
          <Button
            variant="contained"
            color="secondary"
            size="large"
            sx={{ mt: 2 }}
            aria-label="Explore Now"
          >
            Explore Now
          </Button>
        </ScrollLink>
      </Box>
      <Box
        sx={{
          position: "absolute",
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          backgroundColor: "rgba(44, 62, 80, 0.6)",
        }}
      />
    </Box>
  );
}

export default HeroSection;
