// Footer.js

import React from "react";
import { Box, Typography, Link, IconButton, Container } from "@mui/material";
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import InstagramIcon from "@mui/icons-material/Instagram";

/**
 * Footer Component
 * @description Displays the footer with links and social media icons.
 */
const Footer = () => {
  return (
    <Box
      component="footer"
      sx={{
        backgroundColor: "primary.dark",
        color: "white",
        py: 4,
        mt: 4,
      }}
    >
      <Container maxWidth="lg" sx={{ textAlign: "center" }}>
        <Box sx={{ mb: 2 }}>
          <Link href="/contact" color="inherit" underline="hover" sx={{ mx: 1 }}>
            Contact Us
          </Link>
          |
          <Link href="/privacy" color="inherit" underline="hover" sx={{ mx: 1 }}>
            Privacy Policy
          </Link>
          |
          <Link href="/terms" color="inherit" underline="hover" sx={{ mx: 1 }}>
            Terms of Service
          </Link>
          |
          <Link href="/newsletter" color="inherit" underline="hover" sx={{ mx: 1 }}>
            Newsletter Signup
          </Link>
        </Box>
        <Box>
          <IconButton color="inherit" href="https://facebook.com" aria-label="Facebook">
            <FacebookIcon />
          </IconButton>
          <IconButton color="inherit" href="https://twitter.com" aria-label="Twitter">
            <TwitterIcon />
          </IconButton>
          <IconButton color="inherit" href="https://instagram.com" aria-label="Instagram">
            <InstagramIcon />
          </IconButton>
        </Box>
        <Typography variant="body2" sx={{ mt: 2 }}>
          © {new Date().getFullYear()} Town Hall. All rights reserved.
        </Typography>
      </Container>
    </Box>
  );
};

export default Footer;
